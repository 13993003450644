export const state = () => ({
  newSeanceIsLoading: false, // yeni ürün loadingi
  seanceListRequested: false, // daha önce ürün verisi çekilmiş mi?
  seancesAreLoading: true, // ürünler listesi loadingi
  seances: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
})
export const getters = {
  getSeances: (state) => state.seances,
  getSeancesCount: (state) => state.count,
  getSeancesLoading: (state) => state.seancesAreLoading,
}
export const mutations = {
  SET_CLEAR_IT(state) {
    state.seances = []
    state.newSeanceIsLoading = false
    state.seanceListRequested = false
    state.seancesAreLoading = true
    state.count = 0
  },
  // ürünlerin loadingi
  SET_SEANCES_ARE_LOADING(state, payload) {
    state.seancesAreLoading = payload
  },
  SET_SEANCES_LIST_REQUESTED(state, payload) {
    state.seanceListRequested = payload
  },
  // ürünleri projeye ekle
  SET_SEANCES(state, payload) {
    if (!state.seanceListRequested) state.seances = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.seances = []
      state.seanceListRequested = false
    } else state.seanceListRequested = true
    state.seances = [...state.seances, ...payload.data]
    state.count = payload.count
  },
}
export const actions = {
  // ürünleri getir
  async GET_SEANCES(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    // cash tutulması istenmedi. aşağıdaki if bloğu cash bloğunu çalıştırır.
    // if (
    //   state.seances.length !== 0 &&
    //   (search === '') & state.seanceListRequested &&
    //   state.seances.map((seance) => seance.page).includes(page) &&
    //   categoryId === -1
    // )
    //   return // daha önce liste çekilmişse return at.
    commit('SET_SEANCES_ARE_LOADING', true)
    const response = await this.$api.get(
      `UserSeances?page=${page}&searchKey=${search}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_SEANCES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 4,
          }
        }),
      })
      return response.data
    } else
      commit('SET_SEANCES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_SEANCES_ARE_LOADING', false)
    return []
  },
  async SEND_TO_ADD_USERS_SEANCE({ commit, dispatch }, payload) {
    const response = await this.$api.post('UserSeances', {
      productId: payload.seance.id,
      price: SET_TOTAL_PRICE_OF_REZ(payload.appointment),
      currency: payload.currency,
      discount: 0,
      remoteRezId: payload.api.data,
      isFree: SET_TOTAL_PRICE_OF_REZ(payload.appointment) === 0 ? 1 : 0,
    })
    if (response.message === 'OK') {
      commit('SET_SEANCES_LIST_REQUESTED', false)
      dispatch(
        'seances/GET_PACKAGES_OF_CUSTOMER',
        {
          currency: payload.currency,
          remoteId: payload.Id,
          processes: payload.ProcessesData,
        },
        { root: true }
      )
    }
  },
}

const SET_TOTAL_PRICE_OF_REZ = (appointment) => {
  return appointment.Processes.map((p) => p.Price).reduce(
    (partialSum, a) => partialSum + a,
    0
  )
}
