
export default {
  props: {
    adminInfo: {
      default: () => {},
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.settings
    },
  },
}
