
import Types from '@/static/database/Types.json'
import NavItems from '@/components/Shared/Header/NavItems.json'
import { mapGetters } from 'vuex'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  data() {
    return {
      types: NavItems[2].sub,
      st: this.$store.state.settings.contents[this.$i18n.locale],
      policies: this.$store.state.settings.contents.policies
        .map((p) => ({
          ...p,
          checked: false,
        }))
        .filter((p) => p.language === this.$i18n.locale),
    }
  },
  computed: {
    ...mapGetters({
      getPopularProducts: 'popularProducts/getPopularProducts',
    }),
    googleMapLink(){
      return `https://maps.google.com/?q=${this.$store.state.settings.location}`
    },
    showFindUs() {
      return (
        this.$store.state.settings.facebook !== '' ||
        this.$store.state.settings.twitter !== '' ||
        this.$store.state.settings.instagram !== '' ||
        this.$store.state.settings.linkedin !== ''
      )
    },
  },
  methods: {
    convertToSlug,
    type(type) {
      return Types.find((t) => t.type === type).to
    },
    typeName(type) {
      return Types.find((t) => t.type === type).name
    },
  },
}
