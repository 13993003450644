export const state = () => ({
  newEventIsLoading: false, // yeni ürün loadingi
  eventListRequested: false, // daha önce ürün verisi çekilmiş mi?
  eventsAreLoading: true, // ürünler listesi loadingi
  events: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
  page: 1, // sayfa bilgisi
})
export const getters = {
  getEvents: (state) => state.events.filter((e) => e.page === state.page),
  getEventsCount: (state) => state.count,
  getEventLoading: (state) => state.eventsAreLoading,
}
export const mutations = {
  SET_CLEAR_IT(state) {
    state.events = []
    state.newEventIsLoading = false
    state.eventListRequested = false
    state.eventsAreLoading = true
    state.count = 0
  },
  // ürünlerin loadingi
  SET_EVENTS_ARE_LOADING(state, payload) {
    state.eventsAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_EVENTS(state, payload) {
    if (!state.eventListRequested) state.events = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.events = []
      state.eventListRequested = false
    } else state.eventListRequested = true
    state.events = [...state.events, ...payload.data]
    state.events = state.events.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj.page === o.page)) {
        unique.push(o)
      }
      return unique
    }, [])
    state.count = payload.count
    state.page = payload.page
  },
  //  sayfayı set et
  SET_PAGE(state, payload) {
    state.page = payload
  },
}
export const actions = {
  // ürünleri getir
  async GET_EVENTS(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    commit('SET_PAGE', page)
    if (
      state.events.length !== 0 &&
      (search === '') & state.eventListRequested &&
      state.events.map((event) => event.page).includes(page) &&
      categoryId === -1
    )
      return state.events.filter((e) => e.page === state.page) // daha önce liste çekilmişse return at.
    commit('SET_EVENTS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Events?page=${page}&searchKey=${encodeURI(
        search
      )}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_EVENTS', {
        count: response.data.count || 0,
        search,
        categoryId,
        page,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 3,
          }
        }),
      })
      commit('SET_EVENTS_ARE_LOADING', false)
      return response.data
    } else
      commit('SET_EVENTS', {
        count: response.data.count || 0,
        search,
        categoryId,
        page,
        data: [],
      })

    commit('SET_EVENTS_ARE_LOADING', false)
    return []
  },
}
