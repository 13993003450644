export const state = () => ({
  newProductIsLoading: false, // yeni ürün loadingi
  productListRequested: false, // daha önce ürün verisi çekilmiş mi?
  productsAreLoading: true, // ürünler listesi loadingi
  products: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
})
export const getters = {
  getProducts: (state) => state.products,
  getProductsCount: (state) => state.count,
}
export const mutations = {
  SET_CLEAR_IT(state) {
    state.products = []
    state.newProductIsLoading = false
    state.productListRequested = false
    state.productsAreLoading = true
    state.count = 0
  },
  // ürünlerin loadingi
  SET_PRODUCTS_ARE_LOADING(state, payload) {
    state.productsAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_PRODUCTS(state, payload) {
    if (!state.productListRequested) state.products = []
    if (payload.search !== '') {
      state.products = []
      state.productListRequested = false
    } else state.productListRequested = true
    state.products = [...state.products, ...payload.data]
    state.count = payload.count
  },
  SET_PRODUCT_COUNTS(state, payload) {
    state.count = payload
  },
}
export const actions = {
  // ürünleri getir
  async GET_PRODUCTS(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency, lang = '' }
  ) {
    if (
      state.products.length !== 0 &&
      (search === '') & state.productListRequested &&
      state.products.map((product) => product.page).includes(page) &&
      state.products.map((product) => product.category.id).includes(categoryId)
    ) {
      const products = state.products.filter(
        (product) => product.category.id === categoryId
      )
      if (products.length > 0) commit('SET_PRODUCT_COUNTS', products[0].count)
      else commit('SET_PRODUCT_COUNTS', 0)
      return // daha önce liste çekilmişse return at.
    }
    commit('SET_PRODUCTS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Products?page=${page}&searchKey=${search}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}&lang=${lang}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_PRODUCTS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            count: response.data.count,
          }
        }),
      })
      return response.data
    } else
      commit('SET_PRODUCTS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_PRODUCTS_ARE_LOADING', false)
    return []
  },
}
