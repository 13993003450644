
import Modal from '../Shared/Modal/index.vue'
export default {
  data() {
    return {
      showModal: false,
      activePopup: {},
      activeIndex: 0,
    }
  },
  components: {
    Modal,
  },
  computed: {
    popuplist() {
      return this.$store.state.settings.contents.popupList || []
    },
  },
  methods: {
    SET_RND_INDEX() {
      const index = this.popuplist?.length || 0
      this.activeIndex = Math.floor(Math.random() * index)
    },
    SET_SHOW_MODAL() {
      if (this.popuplist?.length > this.activeIndex) {
        this.activePopup = this.popuplist[this.activeIndex]
        this.showModal = true
      }
    },
  },
  mounted() {
    this.SET_RND_INDEX()
    this.SET_SHOW_MODAL()
  },
}
