
import { mapGetters } from 'vuex'
import AdminContentEditable from '~/mixins/AdminContentEditable'

const getImageDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      })
    img.onerror = (error) => reject({ width: 0, height: 0 })
    img.src = url
  })
}
export default {
  mixins: [AdminContentEditable],
  computed: {
    ...mapGetters({
      getRedirectUrl: 'getRedirectUrl',
      getSettings: 'getSettings',
    }),
  },
  data() {
    return {
      width: 0,
    }
  },
  methods: {
    async GET_LOGO() {
      getImageDimensions(this.$store.state.settings.logo)
        .then((response) => {
          this.RESIZE_LOGO(response)
        })
        .catch((err) => {
          this.RESIZE_LOGO(err)
        })
    },
    RESIZE_LOGO({ width, height }) {
      if (width === 0) return (this.width = 120)
      const ratio = height / width
      if (ratio < 0.3) this.width = 180
      else if (ratio < 0.6) this.width = 140
      else if (ratio < 0.9) this.width = 110
      else if (ratio < 1.2) this.width = 80
      else this.width = 120
    },
    SAVE_CONTENT() {
      location.reload()
    },
  },
  mounted() {
    this.GET_LOGO()
  },
}
