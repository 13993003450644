
export default {
  props: {
    value: {
      default: false,
    },
    title: {
      default: '',
    },
    size: {
      default: '',
    },
    sm: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    hideCloseButton: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    okText: {
      type: String,
      default: function () {
        return this.$custom$t('save')
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$custom$t('close')
      },
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  watch: {
    value(data) {
      this.SET_OVERFLOW_HIDDEN_OF_BODY(!data)
    },
  },
  beforeDestroy() {
    this.SET_OVERFLOW_HIDDEN_OF_BODY(true)
  },
  methods: {
    SET_TO_CONTROL_USER_CLICK(e) {
      if (this.persistent) return
      if (e.target.id === 'app-modal') this.$emit('input', false)
    },
    SET_OVERFLOW_HIDDEN_OF_BODY(add = false) {
      if (add) document.body.classList.remove('overflow-hidden')
      else document.body.classList.add('overflow-hidden')
    },
    CLICK_EVENT() {
      this.$emit('ok', this.SET_IS_LOADING)
    },
    SET_IS_LOADING(status = false) {
      this.isLoading = status
    },
  },
}
