export const convertToSlug = (Text) => {
  const t = Tr2En(Text);
  return t.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}


function Tr2En(text) {
  var Maps = {
    "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
    "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
  };
  Object.keys(Maps).forEach(function (Old) {
    const regx = new RegExp(`${Old}`, 'g');
    text = text.replace(regx, Maps[Old]);
  });
  return text;
}