
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      st: this.$store.state.settings.contents[this.$i18n.locale],
    }
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
      getRedirectUrl: 'getRedirectUrl',
    }),
  },
}
