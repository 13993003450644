
import { mapGetters } from 'vuex'
import Modal from '../Shared/Modal/index.vue'
export default {
  components: { Modal },
  data() {
    return {
      eventModal: false,
      event: {},
      intrvl: -1,
      intervalTime: 1000 * 60 * 15, // 15 dakikada bir istek.
      request: false,
    }
  },
  watch: {
    getActiveUser: {
      deep: true,
      handler(data) {
        if (data.token) this.GET_EVENTS_LIST()
      },
    },
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
      getUserIP: 'user/getUserIP',
    }),
  },
  beforeDestroy() {
    clearInterval(this.intrvl)
  },
  methods: {
    SET_DATE(date) {
      if (!date) return ''
      return this.$date.format(date)
    },
    SET_OFFSET_OF_TIME(time) {
      return time
    },
    SET_INTERVAL() {
      const getMinute = new Date().getMinutes() - 1
      const minutes = [60, 15, 30, 45]
      const whichTime =
        minutes.find(
          (time) => time - getMinute >= 0 && time - getMinute <= 15
        ) || 0
      const remainTime = whichTime - getMinute
      this.intervalTime = 1000 * 60 * remainTime
      this.intrvl = setTimeout(() => {
        this.GET_EVENTS_LIST()
        clearTimeout(this.intrvl)
        setTimeout(() => {
          this.SET_INTERVAL() // 1 dakika sonra istek at
        }, 1 * 60 * 1000)
      }, this.intervalTime)
    },
    async GET_EVENTS_LIST() {
      if (!this.getActiveUser.token) return
      const response = await this.$api.get(
        `UserEvents?page=1searchKey=&currency=${this.getUserIP.currency}&limit=5`
      )
      if (response.message === 'OK') {
        this.request = true
        if (response.data.items.length > 0) {
          const today = new Date().getTime()
          const events = response.data.items.filter(
            (d) =>
              this.$date.getTimeWithoutOffset(d.date) - today < 30 * 60000 &&
              this.$date.getTimeWithoutOffset(d.date) + d.duration * 60000 >
                today
          )
          if (events.length > 0) {
            this.event = events[0]
            this.eventModal = true
          }
        }
      }
    },
  },
  mounted() {
    this.SET_INTERVAL()
    this.GET_EVENTS_LIST()
  },
}
