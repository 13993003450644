import { mapGetters } from 'vuex'
// const BASE_URL = 'https://api.heocademy.com'
const BASE_URL = 'https://api.heocademy.com'
import ImageUploadModal from '@/components/HeocademySiteBuilder/imageUploadModal.vue'

export default {
  components: {
    ImageUploadModal,
  },
  data() {
    return {
      updatedElements: [],
      showUploadImage: false,
      isLoadingContext: false,
      alertBox: { status: false, message: '', variant: '', icon: '' },
    }
  },
  computed: {
    ...mapGetters({
      getActiveAdmin: 'user/getActiveAdmin',
      getSettings: 'getSettings',
      getHeocademyToken: 'user/getHeocademyToken',
    }),
    updatedElementsWithoutFile() {
      return this.updatedElements.filter((el) => typeof el.text !== 'object')
    },
    updatedElementsWithFile() {
      return this.updatedElements.filter((el) => typeof el.text === 'object')
    },
  },
  methods: {
    GET_FILE(image, req, key, type, id) {
      const index = this.updatedElements.findIndex(
        (el) =>
          el.id === id && el.key === key && el.req === req && el.type === type
      )
      if (index === -1)
        this.updatedElements.push({
          req,
          key,
          type,
          id,
          text: image.file,
          result: image.result,
        })
      else {
        this.updatedElements[index].text = image.file
        this.updatedElements[index].result = image.result
      }
    },
    GET_UPLOADED_FILE(req, type, key) {
      return (
        this.updatedElements.find(
          (el) => el.req === req && el.type === type && el.key === key
        )?.result || null
      )
    },
    UPDATE_CONTEXT(val, req, key, type, id) {
      const index = this.updatedElements.findIndex(
        (el) =>
          el.id === id && el.key === key && el.req === req && el.type === type
      )
      if (index === -1)
        this.updatedElements.push({
          req,
          key,
          type,
          id,
          text: val,
        })
      else this.updatedElements[index].text = val
    },
    GET_CONTEXT(e, req, key, type, id) {
      const index = this.updatedElements.findIndex(
        (el) =>
          el.id === id && el.key === key && el.req === req && el.type === type
      )
      if (index === -1)
        this.updatedElements.push({
          req,
          key,
          type,
          id,
          text: e.target.innerText,
        })
      else this.updatedElements[index].text = e.target.innerText
    },
    async SAVE_CONTEXT(method, req, id, changeMap, callback = () => false) {
      this.isLoadingContext = true
      const fd = new FormData()
      const data = this.updatedElements.filter(
        (el) => changeMap.includes(el.key) && el.id === id && el.req === req
      )
      data.forEach((element) => {
        fd.append(element.key, element.text)
      })

      const response = await this.CONTEXT_REQUEST(method, req, fd)
      if (response.message === 'OK') {
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')
        setTimeout(() => {
          setTimeout(() => {
            this.updatedElements = []
          }, 2500)
          callback(response)
        }, 1000)
      } else {
        this.SET_ALERT_BOX(this.$t('unsaved'), 'danger', 'close')
      }
      this.isLoadingContext = false
    },
    async SAVE_CONTEXT_WITH_JSON(
      method,
      req,
      id,
      changeMap,
      callback = () => false
    ) {
      this.isLoadingContext = true
      const result = {}
      const data = this.updatedElements.filter(
        (el) => changeMap.includes(el.key) && el.id === id && el.req === req
      )
      data.forEach((element) => {
        result[element.key] = element.text
      })
      const response = await this.CONTEXT_REQUEST(
        method,
        req,
        JSON.stringify(result),
        'application/json'
      )
      if (response.message === 'OK') {
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')
        setTimeout(() => {
          setTimeout(() => {
            this.updatedElements = []
          }, 2500)
          callback(response)
        }, 1000)
      } else {
        this.SET_ALERT_BOX(this.$t('unsaved'), 'danger', 'close')
      }
      this.isLoadingContext = false
    },
    SET_ALERT_BOX(message, variant, icon) {
      this.alertBox = { status: true, message, variant, icon }
      setTimeout(() => {
        this.alertBox.status = false
      }, 5000)
    },
    async REMOVE_CONTEXT(url, callback = () => false) {
      this.isLoadingContext = true
      const response = await this.CONTEXT_REQUEST('DELETE', url)
      if (response.message === 'OK') {
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')
        setTimeout(() => {
          callback(response)
        }, 5000)
      } else {
        this.SET_ALERT_BOX(this.$t('unsaved'), 'danger', 'close')
      }
      this.isLoadingContext = false
    },
    CONTEXT_REQUEST(method, url, data, contentType = '') {
      var requestOptions = {
        method,
        redirect: 'follow',
        headers: {
          Authorization: 'Bearer ' + this.getHeocademyToken,
        },
        body: data,
      }
      if (contentType !== '')
        requestOptions.headers['Content-Type'] = contentType

      return fetch(`${BASE_URL}/${url}`, requestOptions)
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => console.log('error', error))
    },
  },
}
