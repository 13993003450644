
import HeaderTop from './HeaderTop.vue'
import { mapGetters } from 'vuex'
import HeaderBottom from './HeaderBottom.vue'
import HeaderAdminLogin from './HeaderAdminLogin.vue'
export default {
  props: {
    scrolled: {
      default: false,
    },
    scrolling: {
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getRedirectUrl: 'getRedirectUrl',
      getActiveAdmin: 'user/getActiveAdmin',
    }),
  },
  components: { HeaderTop, HeaderBottom, HeaderAdminLogin },
}
