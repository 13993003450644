
export default {
  props: ['error'],
  layout: 'product', // you can set a custom layout for the error page
  data() {
    return {
      status: this.error.statusCode,
      message: this.$custom$t('notFoundPage'),
    }
  },
  async mounted() {
    if (this.$route.query.status) this.status = Number(this.$route.query.status)
    if (Number(this.status) === 500)
      this.message = this.$custom$t('internalServerError')
    await this.$store.dispatch('categories/GET_CATEGORIES');
    await this.$store.dispatch('user/GET_IP');
    this.$router.push('/');
  },
}
