
import { quality } from '@/helpers/imageQuality'
function CONTROL_OF_FILES(files) {
  let valid = true
  for (let i = 0; i < files.length; i++) {
    const element = files[i]
    if (!element.type.includes('image/')) valid = false
  }
  return valid
}
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isDragging: false,
      fileList: [],
    }
  },
  props: {
    deleteLoading: {
      default: false,
    },
    width: {
      default: 680,
    },
    percent: {
      default: 0,
    },
    uploading: {
      default: false,
    },
    files: {
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getSettings: 'getSettings',
    }),
  },
  methods: {
    GET_DROP_ITEM(e) {
      e.preventDefault()
      this.isDragging = false
      const files = e.dataTransfer.files
      if (!CONTROL_OF_FILES(files)) return
      this.$refs.file.files = files
      this.UPLOAD_FILES(files)
    },
    SET_FILES(e) {
      this.UPLOAD_FILES(e.target.files)
    },
    async UPLOAD_FILES(files) {
      if (files.length === 0) return
      this.fileList = files
      const image = await quality(files[0], this.width, 1, this.getSettings.primaryColor)
      this.$emit('response:file', image)
    },
  },
}
