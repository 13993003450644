
export default {
  methods: {
    SET_STYLE_OF_APP() {
      var css = `:root {
  --primary-color: ${this.$store.state.settings.primaryColor};
  --primary-color-lighten: rgba(var(--primary-color), 0.7);
  --theme-color: ${this.$store.state.settings.themeColor};
  --theme-color-lighten: rgba(var(--theme-color), 0.8);
  --banner-text-color: ${this.$store.state.settings.bannerColor};
  --banner-text-color-darken: #fff;
}`,
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style')
      head.appendChild(style)
      style.type = 'text/css'
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
      this.loaded = true
    },
  },
  mounted() {
    this.SET_STYLE_OF_APP()
  },
}
