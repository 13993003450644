
import Subscription from './Subscription.vue'
import FooterBottom from './FooterBottom.vue'
import FooterCopyright from './FooterCopyright.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Subscription, FooterBottom, FooterCopyright },
  computed: {
    ...mapGetters({
      getRedirectUrl: 'getRedirectUrl',
    }),
  },
}
