
import { mapGetters } from 'vuex'
import AdminContentEditable from '~/mixins/AdminContentEditable'
import Modal from '@/components/Shared/Modal/index.vue'
export default {
  components: {
    Modal,
  },
  mixins: [AdminContentEditable],
  data() {
    return {
      mobileInfo: false,
      showSocialMediasModal: false,
      isLoadingSocialMedia: false,
      updateSocialMedias: [],
      socialMedias: [
        { value: 'instagram', label: 'Instagram' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'twitter', label: 'Twitter (X)' },
        { value: 'linkedin', label: 'Linkedin' },
        { value: 'youtube', label: 'Youtube' },
        { value: 'tiktok', label: 'Tiktok' },
      ],
      socialContactText: { path: '', value: '' },
      alertBox: [],
    }
  },
  props: {
    scrolling: {
      default: false,
    },
  },
  watch: {
    mobileInfo() {
      this.$emit('mobileInfo', this.mobileInfo)
    },
    scrolling(data) {
      if (data) this.mobileInfo = false
    },
  },
  computed: {
    showFindUs() {
      return (
        this.$store.state.settings.facebook !== '' ||
        this.$store.state.settings.twitter !== '' ||
        this.$store.state.settings.instagram !== '' ||
        this.$store.state.settings.linkedin !== ''
      )
    },
    ...mapGetters({
      getRedirectUrl: 'getRedirectUrl',
    }),
  },
  methods: {
    wc_hex_is_light(color) {
      const hex = color.replace('#', '')
      const c_r = parseInt(hex.substring(0, 0 + 2), 16)
      const c_g = parseInt(hex.substring(2, 2 + 2), 16)
      const c_b = parseInt(hex.substring(4, 4 + 2), 16)
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
      return brightness > 155
    },
    COLOR() {
      const themeColor = this.$store.state.settings.themeColor
      if (this.wc_hex_is_light(themeColor)) return '#000'
      else return '#fff'
    },
    GET_ALERT_BOX(item) {
      const alertItem = this.alertBox.find((ab) => {
        if (ab.message === 'OK' && ab.data?.id) {
          return item.id === ab.data.id || item.value === ab.data.value
        } else if (ab.message === 'OK') {
          return true
        } else if (ab.message !== 'OK') {
          return true
        }
        return false
      })
      if (alertItem) {
        if (alertItem.message === 'OK')
          return `bg-success text-white icofont-check p-2 rounded`
        else return `bg-danger text-white icofont-close p-2 rounded`
      } else return false
    },
    async SAVE_SOCIAL_MEDIA() {
      this.alertBox = []
      this.isLoadingSocialMedia = true
      const updateSocialMedias = this.updateSocialMedias.filter(
        (usm) => usm.id > -1 && usm.isUpdated === 1 && usm.isDeleted === 0
      )
      const deletedSocialMedias = this.updateSocialMedias.filter(
        (usm) => usm.isDeleted === 1
      )
      const newSocialMedias = this.updateSocialMedias.filter(
        (usm) => usm.id === -1
      )

      const response = await Promise.all([
        ...updateSocialMedias.map((usm) =>
          this.CONTEXT_REQUEST(
            'PUT',
            `SocialMedia/${usm.id}`,
            JSON.stringify({
              key: usm.path,
              path: usm.path,
              value: usm.value,
              companyId: this.getSettings.id,
              isActive: 1,
            }),
            'application/json'
          )
        ),
        ...deletedSocialMedias.map((usm) =>
          this.CONTEXT_REQUEST(
            'DELETE',
            `SocialMedia/${usm.id}`,
            {},
            'application/json'
          )
        ),
        ...newSocialMedias.map((usm) =>
          this.CONTEXT_REQUEST(
            'POST',
            `SocialMedia`,
            JSON.stringify({
              key: usm.path,
              path: usm.path,
              value: usm.value,
              companyId: this.getSettings.id,
              isActive: 1,
            }),
            'application/json'
          )
        ),
      ])
      this.alertBox = response
      setTimeout(() => {
        this.alertBox = []
        location.reload()
      }, 2500)
      this.isLoadingSocialMedia = false
    },
    UNDO_SOCIAL_MEDIA(i) {
      this.updateSocialMedias[i].isDeleted = 0
    },
    DELETE_SOCIAL_MEDIA(i, item) {
      if (item.id === -1) this.updateSocialMedias.splice(i, 1)
      else this.updateSocialMedias[i].isDeleted = 1
    },
    ADD_SOCIAL_MEDIA() {
      this.updateSocialMedias.push({
        path: this.socialContactText.path,
        value: this.socialContactText.value,
        id: -1,
        isUpdated: 0,
        isDeleted: 0,
        isInsterted: 1,
      })
    },
    CHECK_SOCIAL_MEDIA(val, index) {
      if (index === -1) {
        const item = this.socialMedias.find((s) => val.includes(s.value))
        if (item) this.socialContactText.path = item.value
      } else {
        const item = this.socialMedias.find((s) => val.includes(s.value))
        if (item) {
          this.updateSocialMedias[index].path = item.value
          this.updateSocialMedias[index].isUpdated = 1
        }
      }
    },
    SHOW_SOCIAL_MEDIAS() {
      if (!this.getActiveAdmin.token) return
      this.updateSocialMedias = []
      this.$store.state.settings.socialMediaSettings.forEach((s) => {
        this.updateSocialMedias.push({
          ...s,
          isDeleted: 0,
          isUpdated: 0,
          isInsterted: 0,
        })
      })
      this.showSocialMediasModal = true
    },
    SET_MOBILE_INFORMATION() {
      this.mobileInfo = !this.mobileInfo
    },
    SAVE_CONTENT() {
      location.reload()
    },
  },
}
