export default async ({ app, req, redirect }, inject) => {
  // server bağlandıktan sonra istek atılır.
  const settings = app.store.getters['getSettings'] // await app.store.dispatch('GET_COMPANY_SETTINGS', { url, redirect });
  return inject('settings', settings)
}
// sponsor veya referanslar kısımı assets > imgs > sponsor içine 01,02,03,04,05.png olarak ekleyin.
// static > sass > sub-stylesheet > _variables // colors içi değiştirilir.
// about resimi için assets > imgs > about > 01.png eklenir.
// site içi müşteri ürün sayısı veren kısım card resimleri için assets > imgs > features-01 ve features-02.png değiştir.
// müşteri yorumları kısmındaki resim için assets > imgs > feedback-01.png değiştir
