
import Modal from '@/components/Shared/Modal/index.vue'
import UploadImageArea from './UploadImageArea.vue'
export default {
  components: {
    Modal,
    UploadImageArea,
  },
  props: {
    value: {
      default: false,
    },
    width: {
      default: 680,
    },
  },
  methods: {
    GET_FILE(image) {
      this.$emit('response:file', image)
    },
  },
}
