import axios from 'axios'
export const REZERVATION_REQUEST = (
  url,
  method = 'GET',
  body,
  headers = {}
) => {
  var config = {
    method,
    url: `https://api.rezzervasyon.com/${url}`,
    //url: `http://192.168.1.28:4000/${url}`,
    headers,
    data: body,
  }

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      return error.response.data
    })
}
