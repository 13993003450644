// import
import Vue from 'vue'

import VCalendar from 'v-calendar'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar)
// If used in Nuxt.js/SSR, you should keep it only in browser build environment
import 'videojs-contrib-hls'
if (process.browser) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/ssr')
  Vue.use(VueAwesomeSwiper)
}
