export default ({ app, store }, inject) => {
  // Inject $hello(msg) in Vue, context and store.

  inject('custom$t', (str, obj) => {
    // use `$te` and `$t
    const i18n = app.i18n
    const companyId = store.state.settings.id;
    const customStr = str + 'Custom.' + companyId
    return i18n.t && i18n.te
      ? i18n.te(customStr)
        ? i18n.t(customStr, obj)
        : i18n.t(str, obj)
      : i18n.t(str, obj)
        ? i18n.t(str, obj)
        : i18n.t(str, obj)
  })
}


