const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['deauth'] = require('../middleware/deauth.js')
middleware['deauth'] = middleware['deauth'].default || middleware['deauth']

middleware['onlyRedirectProject'] = require('../middleware/onlyRedirectProject.js')
middleware['onlyRedirectProject'] = middleware['onlyRedirectProject'].default || middleware['onlyRedirectProject']

middleware['returnUrl'] = require('../middleware/returnUrl.js')
middleware['returnUrl'] = middleware['returnUrl'].default || middleware['returnUrl']

export default middleware
