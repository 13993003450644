
export default {
  data() {
    return {
      showCookies: true,
    }
  },
  methods: {
    SET_COOKIES() {
      localStorage.setItem('cookiesAction', true)
      this.showCookies = false
    },
  },
  mounted() {
    const cookies = localStorage.getItem('cookiesAction')
    if (cookies) this.showCookies = false
  },
}
