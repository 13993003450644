export const state = () => ({
  newShowcaseProductIsLoading: false, // yeni ürün loadingi
  showcaseProductListRequested: false, // daha önce ürün verisi çekilmiş mi?
  showcaseProductsAreLoading: true, // ürünler listesi loadingi
  showcaseProducts: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
})
export const getters = {
  getShowcaseProducts: (state) =>
    state.showcaseProducts.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    ),
  getShowcaseProductsCount: (state) => state.count,
}
export const mutations = {
  // ürünlerin loadingi
  SET_SHOWCASE_PRODUCTS_ARE_LOADING(state, payload) {
    state.showcaseProductsAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_SHOWCASE_PRODUCTS(state, payload) {
    if (!state.showcaseProductListRequested) state.showcaseProducts = []
    state.showcaseProductListRequested = true
    state.showcaseProducts = [...state.showcaseProducts, ...payload.data]
    state.count = payload.count
  },
  SET_REMAIN_OF_EVENT(state, payload) {
    state.showcaseProducts[payload.index].remain = payload
  },
  SET_INTERVAL_OF_EVENT(state, payload) {
    state.showcaseProducts[payload.index].intrvl = payload.intrvl
  },
  SET_REMOVE_EVENT_ITEM(state, payload) {
    state.showcaseProducts[payload.index].closestEvent = null
  },
}
export const actions = {
  // ürünleri getir
  async GET_SHOWCASE_PRODUCTS({ state, commit }, { currency }) {
    if (
      state.showcaseProducts.length !== 0 &&
      state.showcaseProductListRequested
    )
      return // daha önce liste çekilmişse return at.
    commit('SET_SHOWCASE_PRODUCTS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Products/Showcase?page=1&currency=${currency}&limit=6`
    )
    if (response.message === 'OK') {
      commit('SET_SHOWCASE_PRODUCTS', {
        count: response.data.count || 0,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            intrvl: -1,
            remain: {
              day: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
            ...d,
            page: 1,
          }
        }),
      })
      return response.data
    } else
      commit('SET_SHOWCASE_PRODUCTS', {
        count: response.data.count || 0,
        data: [],
      })
    commit('SET_SHOWCASE_PRODUCTS_ARE_LOADING', false)
    return []
  },
}
