export const state = () => ({
  newPopularProductIsLoading: false, // yeni ürün loadingi
  popularProductListRequested: false, // daha önce ürün verisi çekilmiş mi?
  popularProductsAreLoading: true, // ürünler listesi loadingi
  popularProducts: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
})
export const getters = {
  getPopularProducts: (state) => state.popularProducts,
  getPopularProductsCount: (state) => state.count,
}
export const mutations = {
  // ürünlerin loadingi
  SET_POPULAR_PRODUCTS_ARE_LOADING(state, payload) {
    state.popularProductsAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_POPULAR_PRODUCTS(state, payload) {
    if (!state.popularProductListRequested) state.popularProducts = []
    state.popularProductListRequested = true
    state.popularProducts = [...state.popularProducts, ...payload.data]
    state.count = payload.count
  },
}
export const actions = {
  // ürünleri getir
  async GET_POPULAR_PRODUCTS(
    { state, commit, rootState },
    { currency, lang = 'tr' }
  ) {
    if (state.popularProducts.length !== 0 && state.popularProductListRequested)
      return // daha önce liste çekilmişse return at.
    commit('SET_POPULAR_PRODUCTS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Products/Popular?currency=${currency}&langCode=${lang}&orderCond=${rootState.settings.popularity}`
    )
    if (response.message === 'OK') {
      commit('SET_POPULAR_PRODUCTS', {
        count: response.data.length,
        data: response.data.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page: 1,
          }
        }),
      })
      return response.data
    } else
      commit('SET_POPULAR_PRODUCTS', {
        count: 0,
        data: [],
      })
    commit('SET_POPULAR_PRODUCTS_ARE_LOADING', false)
    return []
  },
}
