export const state = () => ({
  newBlogIsLoading: false, // yeni ürün loadingi
  blogListRequested: false, // daha önce ürün verisi çekilmiş mi?
  blogsAreLoading: true, // ürünler listesi loadingi
  blogs: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
  page: 1, // sayfa bilgisi
})
export const getters = {
  getBlogs: (state) => state.blogs.filter((b) => b.page === state.page),
  getBlogsCount: (state) => state.count,
  getBlogsLoading: (state) => state.blogsAreLoading,
}
export const mutations = {
  // ürünlerin loadingi
  SET_BLOGS_ARE_LOADING(state, payload) {
    state.blogsAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_BLOGS(state, payload) {
    if (!state.blogListRequested) state.blogs = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.blogs = []
      state.blogListRequested = false
    } else state.blogListRequested = true
    state.blogs = [...state.blogs, ...payload.data]
    state.blogs = state.blogs.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj.page === o.page)) {
        unique.push(o)
      }
      return unique
    }, [])
    state.count = payload.count
  },
  SET_CLEAR_IT(state) {
    state.blogs = []
    state.newBlogIsLoading = false
    state.blogListRequested = false
    state.blogsAreLoading = true
    state.count = 0
  },
  //  sayfayı set et
  SET_PAGE(state, payload) {
    state.page = payload
  },
}
export const actions = {
  // ürünleri getir
  async GET_BLOGS(
    { state, commit, rootState },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    commit('SET_PAGE', page)
    const isBlog = rootState.settings.isBlog === 1
    const hasBlog = rootState.settings?.components?.includes('Blogs') || false
    if (!hasBlog || !isBlog) return
    if (
      state.blogs.length !== 0 &&
      (search === '') & state.blogListRequested &&
      state.blogs.map((blog) => blog.page).includes(page) &&
      categoryId === -1
    )
      return // daha önce liste çekilmişse return at.
    commit('SET_BLOGS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Blogs?page=${page}&searchKey=${encodeURI(
        search
      )}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_BLOGS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 5,
          }
        }),
      })
      commit('SET_BLOGS_ARE_LOADING', false)
      return response.data
    } else
      commit('SET_BLOGS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_BLOGS_ARE_LOADING', false)
    return []
  },
}
