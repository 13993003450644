export const state = () => ({
  newBookIsLoading: false, // yeni ürün loadingi
  bookListRequested: false, // daha önce ürün verisi çekilmiş mi?
  booksAreLoading: true, // ürünler listesi loadingi
  books: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
  page: 1, // sayfa bilgisi
})
export const getters = {
  getBooks: (state) => state.books.filter((b) => b.page === state.page),
  getBooksCount: (state) => state.count,
  getBooksLoading: (state) => state.booksAreLoading,
}
export const mutations = {
  // ürünlerin loadingi
  SET_BOOKS_ARE_LOADING(state, payload) {
    state.booksAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_BOOKS(state, payload) {
    if (!state.bookListRequested) state.books = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.books = []
      state.bookListRequested = false
    } else state.bookListRequested = true
    state.books = [...state.books, ...payload.data]
    state.books = state.books.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj.page === o.page)) {
        unique.push(o)
      }
      return unique
    }, [])
    state.count = payload.count
  },
  SET_CLEAR_IT(state) {
    state.books = []
    state.newBookIsLoading = false
    state.bookListRequested = false
    state.booksAreLoading = true
    state.count = 0
  },
  //  sayfayı set et
  SET_PAGE(state, payload) {
    state.page = payload
  },
}
export const actions = {
  // ürünleri getir
  async GET_BOOKS(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    commit('SET_PAGE', page)

    if (
      state.books.length !== 0 &&
      (search === '') & state.bookListRequested &&
      state.books.map((book) => book.page).includes(page) &&
      categoryId === -1
    )
      return // daha önce liste çekilmişse return at.
    commit('SET_BOOKS_ARE_LOADING', true)
    const response = await this.$api.get(
      `Books?page=${page}&searchKey=${encodeURI(
        search
      )}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_BOOKS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 2,
          }
        }),
      })
      commit('SET_BOOKS_ARE_LOADING', false)
      return response.data
    } else
      commit('SET_BOOKS', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_BOOKS_ARE_LOADING', false)
    return []
  },
}
